<template>
    <h1>Reeducating I.D. &copy;</h1>
    <p>(Zurückgehende Neuerziehung)</p>

    <h2>1. Ganzheitliche Entwicklungspädagogik zur Unterstützung bei
        entwicklungsneurologischen Verzögerungen durch anhaltende
        Frühkindliche Reflexe</h2>
    <ul>
        <li>Integration anhaltender/restreaktiver Frühkindlicher Reflexe</li>
        <li>Förderung der Bewegungs- und Lernfähigkeit</li>
        <li>Stärkung der emotionalen und sozialen Kompetenzen</li>
    </ul>
    <h2> 2. Integration negativer Erlebnisse während der Schwangerschaft, bei
        der Geburt und in der frühkindlichen Entwicklung</h2>
    <ul>
        <li>Stärkung/Aufbau der Eltern-Kind-Bindung</li>
        <li>(Neu)-Gestaltung der Eltern-Kind-Beziehung</li>
        <li>(Neu)-Erziehung mit Liebe, Stärke, Grenzen und Konsequenz</li>
    </ul>

    <h2>Vorwort</h2>
    <p>
        Ich blicke auf langjährige Erfahrungen in meinen Praxisfeldern zurück und konnte beobachten, wie sich Kindheit und
        Jugend durch einen schnellen Wandel der Gesellschaft verändert
        haben.
        Dieser Wandel stellt alle, die mit Kindern leben und arbeiten, immer wieder vor „neue Herausforderungen“.

    </p>
    <p>
        Eltern, Erzieher und Lehrer werden zunehmend mit „Handicaps und Problematiken“ in der
        motorischen Entwicklung, im emotionalen-sozialen Verhalten und in der Lern- und
        Leistungsfähigkeit konfrontiert.
        Als Ursachen werden je nach Forschungsansatz und Denkmodel, unterschiedliche Faktoren
        angeführt.
    </p>
    <p>
        Die Erkenntnisse der modernen Entwicklungs-, Gehirn- und Lernforschung, (Heinz Mandel
        Prof. Dr. G. Hüther, Manfred Spitzer, Dr. Paul Dennision) zeigen auf, dass Wahrnehmung,
        Lernen und emotionale/soziale Kompetenz, ein ganzheitliches Gefüge bilden, an dem
        Körper, Geist und Emotionen gleichermaßen beteiligt sind.
    </p>
    <p>
        Ich beobachte, dass immer mehr Kindern die „grundlegende physiologische, emotionale
        und soziale Basis“ fehlt, die aber „Vorrausetzung“ dafür sind, dass <b>ganzheitliches Lernen</b>
        (motorisches, geistig-kognitives, emotional-soziales) überhaupt möglich wird.
    </p>
    <p>
        Bei einer Fortbildung lernte ich im Jahr 2000 die Forschung des INPP (Institut für Neurologische Physiologie und
        Psychologie) in Chester, England kennen.
        Im INPP untersuchten und erforschten Sally Goodard und ihr Mann Peter Blyth die Frühkindlichen Reflexe.
        Sie veröffentlichten ihre Forschungsergebnisse in einem Buch für Eltern, Lehrer, Erzieher
        und Therapeuten unter dem Titel: <b>„Greifen und BeGreifen“ - wie Lern- und Verhaltensstörungen mit frühkindlichen
            Reflexen zusammenhängen“</b> (VAK Verlags GmbH)
    </p>
    <p>
        Diese Erkenntnisse waren und sind bis heute eine „Offenbarung“ für meine pädagogische
        Arbeit. Ich bekam viele Antworten auf Fragen - die in meiner täglichen Praxis mit Kindern,
        Eltern und anderen Helfersystemen - unbeantwortet geblieben waren.
    </p>
    <p>
        Ich verstand, dass Kinder und Jugendliche, aber auch Erwachsenen die von „restaktiven,
        anhaltenden Frühkindlichen Reflexen“ betroffen sind unterschiedliche „Defizite“ zeigen,
        vielschichtige Probleme haben und so manche Herausforderungen meistern müssen - und
        mit ihnen, ihre Umwelt.
    </p>
    <p>
        Da die Thematik der „Restreaktionen Frühkindlicher Reflexe“, Eltern, Erziehern und Lehrern
        meistens unbekannt ist, werden weder motorische Defizite, Wahrnehmungs-, Lern- und
        Konzentrationsstörungen, noch „negative und aggressive Verhaltensreaktionen“ sowie
        extreme emotionale Schwankungen, damit in Verbindung gebracht.
    </p>
    <p>
        Anhaltende Frühkindliche Reflexe können sich in allen Ebenen des menschlichen Seins, auf
        vielfältige Art und Weise auswirken - und münden oftmals in einen Kreislauf aus Konflikten“
        - der nur schwer von Eltern, Erziehern, Lehrern - und vor allem von den Kindern selber -
        durchbrochen werden kann.
    </p>
    <p>
        Hier setzt das „Reeducating I.D. ©“ an, aus einem anstrengenden, hilflosen und frustrierenden Beziehungs-,
        Erziehungs-, Lern- Lehralltag auszusteigen.
        Es schafft die Möglichkeit, einen neuen Kreislauf entstehen zu lassen, der über das
        Verstehen zur Wertschätzung und gegenseitiger Unterstützung führt, der sich durch
        respektvolle Zusammenarbeit „verschiedener Helfersysteme“ mit Stärken und Kompetenzen
        füllt und sich über die positiven Veränderungen, die dadurch entstehen können, immer
        wieder neu erschafft.
    </p>
    <p>
        In meiner Praxisarbeit, haben Betroffene (Kinder, Jugendliche, Erwachsene), Eltern,
        Erzieher, Lehrer, Therapeuten verschiedener Fachrichtungen und ich die Erfahrung
        gemacht, dass sich die Langzeitsymptome, z. B. motorische Entwicklungsverzögerungen,
        impulsives-aggressives oder schüchternes-zurückhaltendes Verhalten, Wahrnehmungsund Lernschwierigkeiten,
        Konzentrationsprobleme, grob- und feinmotorische Bewegungsdefizite, bis hin zu reflexbedingten Einnässen und
        Einkoten, positiv verändern, bzw. nicht
        mehr auftreten.
    </p>
    <p>
        Ich möchte darauf hinweisen, dass es „die Methode“ - oder „das Verfahren“ nicht gibt und
        das Probleme, Herausforderungen und Störungen viele Ursachen und Hintergründe haben
        können, so dass es Sinn macht, mit anderen Therapeuten und Einrichtungen parallel
        zusammen zu arbeiten.
        Der Maßstab, ob das notwendig erscheint oder nicht, ist immer der Klient, bzw. die Eltern
        selbst, in dem sie in ihrer persönlichen Verantwortung bleiben.
    </p>
    <h2>Danksagung</h2>
    <p>
        Ich bedanke mich bei den Eltern und Kindern, Erziehern und Lehrern, die mir und meiner
        Arbeit „Vertrauen“ geschenkt haben.
    </p>
    <p>
        Dankbar bin ich für die wertschätzende und zum Teil eng vernetzte Zusammenarbeit mit
        Heilpraktikern, Ärzten, Psychologen, Ergo- und Physiotherapeuten sowie Logopäden.
        <b>Ein ganz herzliches Dankeschön, geht an die Klienten, Eltern und Kinder, die sich
            bereit erklärten und erklären, ihre Beobachtungen, positive Entwicklungen und
            Veränderungen aufzuschreiben und mir diese für Veröffentlichungen zur Verfügung
            zu stellen.</b>
    </p>
    <p>
        Mein ganz besonderer Dank gilt Herrn Prof. Dr. Gerald Hüther, der mich ermutigte das
        Reeducating I.D.© als „ganzheitliche Entwicklungspädagogik“ zu konzipieren und die
        Erfahrungen und Erlebnisse der Klienten zu dokumentieren.
    </p>

    <h2>Einleitung Reeducating I.D. &copy;</h2>
    <p>
        Ein Teil meiner Arbeit basiert auf den Forschungsergebnissen des INPP (Institut für
        Neurologische Physiologie und Psychologie, Chester, England).
        Die Erkenntnisse dieser Forschungsarbeit habe ich in meine Methode, in das Reeducating
        I.D. ©, (Zurückgehende Neuerziehung) einfließen lassen und durch eigene, von mir
        entwickelte Techniken und um andere methodische Ansätze erweitert.
    </p>
    <p>
        Mir war es wichtig, eine „<b>ganzheitliche Entwicklungspädagogik</b>“ anbieten zu können, die
        Verfahren und Methoden beinhaltet, die den Körper, inklusive seiner feinstofflichen, energetischen Phänomene
        (Meridiane, Aura, Chakren), den Geist, die Seele, die Emotionen
        gleichermaßen anspricht und auch die Spiritualität nicht ausklammert.
        Ich nutze kinesiologische, systemische und bioenergetische Methoden, um Klienten,
        (Kind/Jugendlicher/Erwachsener) die durch „restaktive frühkindliche Reflexe“ beeinflusst
        werden, das „Balanceangebot“ (Arbeitsbegriff aus der Kinesiologie) zu machen, die
        „Kompensationsstrategien“ (Ausgleich schaffen für etwas, was nicht, wie vorgesehen
        ablaufen kann) und „symptomatischen Langzeitfolgen“, zu verändern, bestenfalls
        aufzulösen.
    </p>
    <p>
        Hierbei findet keine Diagnose, Bewertung, Begutachtung und/oder Arbeit an Symptomen im
        medizinisch/therapeutisch oder psychologischen Sinne statt.
        Das Reeducating I.D. © bietet Begleitung und Beratung im Rahmen meiner zertifizierten
        Weiterbildungen an.
    </p>
    <p>
        Grundlage für die Begleitung und Beratung bilden die formulierten Ziele der Klienten, die
        damit selbstbestimmt entscheiden, wohin sich die Lern- und persönlichen Veränderungsprozesse entwickeln sollen.
        Dabei steht die Ressourcen- und Lösungsorientierte Arbeitsweise im Mittelpunkt.
    </p>
    <div class="rahmen">
        <p>
            Das Reeducating I.D. © ist kein Ersatz für medizinische und/oder psychotherapeutische
            Behandlungen und dient nicht der Heilung von Krankheiten.
        </p>
        <p>
            Bei gesundheitlichen Beschwerden und Krankheiten sollte eine medizinische und/ oder
            psychotherapeutische Behandlung in Anspruch genommen werden.
        </p>
        <p>
            Es macht Sinn den behandelnden Arzt u./o. Psychotherapeuten über die ganzheitliche
            Begleitung durch das Reeducating I.D. &copy; Verfahren zu informieren.
        </p>
    </div>
    <p>Verwendete Literatur in dieser Broschüre:</p>
    <p>
        „Greifen und BeGreifen“, Sally Godhard, (VAK Verlags GmbH) „Bewegungen die Heilen“
        Dr. Harald Blomberg (VAK Verlags GmbH), „Bausteine der kindlichen Entwicklung“, A. Jean
        Ayres (Springer Verlag) „Handbuch der Sinne“, Renate Zimmer (Herder Verlag) Flügel und
        Wurzeln, Dorothea Beigel (Verlag Modernes Lernen Dortmund) „Neues vom Zappelphilipp“
        Prof. Dr. Dr. Gerald Hüter (Beltz)
    </p>

    <h3>1. Was sind Frühkindliche Reflexe?</h3>

    <p>Frühkindliche Reflexe sind automatische, gleichbleibende Bewegungsabläufe, die vom
        Hirnstamm des Babys gesteuert werden. Sie entwickeln und hemmen sich in einer
        aufeinander abgestimmten Reihenfolge während der Schwangerschaft. Einige werden bei
        der Geburt ausgelöst und helfen dem Kind durch spezifische Dreh-, Beuge- und
        Streckbewegungen auf dem Weg ins Leben und sichern in den ersten Lebensmonaten das
        Überleben des Babys.
        Alle Reflexe haben einen direkten Bezug zum Labirinthsystem (Gleichgewichtsorgan) und
        haben die Aufgabe, die Bewegungs-, Sinnes- und Wahrnehmungsentwicklung des Kindes
        voranzubringen.
        Im Laufe der frühkindlichen (motorischen) Entwicklung reifen Bewegungs- und Handlungsmuster heran, die sich in den
        höheren Zentren des Gehirnes etablieren und die das Kind
        zunehmend über den Willen zu steuern lernt. Haben die Frühkindlichen Reflexe „ihre
        Aufgaben erfüllt“ - werden sie von den höheren, sich zunehmend vernetzenden Gehirnarealen überlagert, oder wie auch
        gesagt wird - „ sie werden im Hirnstamm schlafen gelegt“.</p>

    <h4>1.1 Was sind anhaltende/restaktive Frühkindliche Reflexe?</h4>

    <p>
        „Restaktive/anhaltende Reflexe sind, aus der Säuglings- und Kleinkindzeit stammende
        „automatische Abläufe“, die sich auf die Gehirn- und Körperstrukturen auswirken. Sie haben
        Einfluss auf Bewegungsabläufe, biochemische Körperprozesse (Ausschüttung von Stresshormonen) und daran gekoppelte
        emotionale Reaktionen (Kampf-Flucht-Erstarren). Diese
        Restreaktionen werden von einem Betroffenen meistens gar nicht bewusst wahrgenommen.
        Oftmals zeigen sie sich als „negative Aspekte“, die sich störend und beeinträchtigend in
        verschiedenen Bereichen des täglichen Lebens auswirken können.
        Goodhard/Blyth benennen eine Vielzahl von blockierenden Langzeitfolgen die sich im Laufe
        der Entwicklung bei einem Kind (Erwachsenen) in unterschiedlichen Symptomen zeigen
        können.
    </p>

    <h4>1.2 Indikatoren für Entwicklungsneurologische Verzögerungen</h4>

    <p>
        <b>In der Schwangerschaft:</b><br />
        Heftiges Erbrechen, Virusinfektionen in bestimmten Phasen der Schwangerschaft,
        Toxoplasmose, unbehandelter Diabetes, Alkohol,- Drogen und Nikotinkonsum,
        Strahlenbelastung, mangelnde Funktion des Mutterkuchens (Plazentainsuffizienz),
        Bluthochdruck, Starker Stress, Vorzeitige Wehen, Liegen in den Schwangerschaft,
        Drohende Fehlgeburt
    </p>
    <p>
        <b>Geburt und Störungen beim Neugeborenen:</b><br />
        Verlängerte Wehen, Sturzgeburt, Steißgeburt, Zangen- oder Saugglockengeburt,
        Kaiserschnitt, Afrikanische Wickeltechnik, Nabelschnurumwicklung, Frühgeburt, Übertragen
        des Kindes mit Geburtseinleitung, schlechte Herztöne (CTG Werte) beim Fötus, Fehllage
        des Mutterkuchens (Plazenta praevia) Geringes Geburtsgewicht (unter 4,5 Pfund),
        Brutkasten, verformter Schädel, Blaues Baby, Blutergüsse, blaue Flecken, Wiederbelebung
        des Babys, Schwere Gelbsucht
    </p>

    <h4>1.3 Zusammenfassung verschiedener Indikatoren für anhaltende/restaktive
        frühkindliche Reflexe</h4>

    <p>
        <b>In der Entwicklung, bei der Bewegung, in der Haltung und beim Sport:</b>
    </p>
    <ul>
        <li>Starke Anpassungsstörungen: Unruhige, schlecht schlafende Babys, aus dem
            Schlaf aufschreckende Babys (mit Schreien), viel Weinen, Schrei- und Spuckkinder, Probleme beim Stillen und
            Füttern während der ersten sechs Monate</li>
        <li>Krankheiten während der ersten 18 Monate in Verbindung mit hohen Fieber</li>
        <li>Delirium oder Krämpfe</li>
        <li>ungünstige Reaktionen auf die üblichen Impfungen</li>
        <li>häufige Hals-Nasen-Ohren Infektionen</li>
        <li>Babys vermeiden, oder lassen Drehen, Rollen, Kriechen und Krabbeln aus</li>
        <li>Kleinkinder stolpern über die eigenen Füße, häufiges Hinfallen,Ungeschicklichkeit, Kleckern, Gegenstände
            umstoßen</li>
        <li>Gleichgewichtsprobleme: Roller, Fahrrad, Inliner, Skateboard fahren ist schwierig, kann nicht gut oder gar nicht
            hüpfen, Seil-, Bock- und Trampolinspringen gelingen nicht gut</li>
        <li>Probleme beim Schwimmen lernen, mangelnde Koordination von Kopf, Armen und Beinen, Probleme zu tauchen oder Kopf
            über Wasser zu halten</li>
        <li>einen Ball zu werfen und/oder zu fangen gelingt nicht gut</li>
        <li>Generelle Abneigung gegen sportliche Aktivitäten, Vermeidung von Bewegung, traut sich nicht zu klettern,
            rutschen, schaukeln oder ist dabei sehr waghalsig und ungestüm</li>
        <li>Spätes Krabbeln/Laufen lernen, Schlechte Körperhaltung und auffällige Gangbilder, Spätes Sprechen lernen (älter
            als 18 Monate)</li>
        <li>Schwierigkeiten beim Anziehen lernen, z.B. beim Knöpfen, Binden von Schnürsenkeln</li>
        <li>Daumenlutschen bis zum Alter von fünf Jahren oder darüber hinaus</li>
        <li>Einnässen/Einkoten über die „übliche Entwicklungsphase“ hinaus</li>
    </ul>

    <p>
        <b>In der Wahrnehmung, Lern- und Konzentrationsfähigkeit, beim Erwerb der
            Kulturtechniken Lesen, Schreiben und Rechnen</b>
    <ul>
        <li>Kann rechts und links gar nicht oder nicht gut unterscheiden</li>
        <li>Vermeidet feinmotorische Tätigkeiten: Schneiden, Falten, Basteln, Malen</li>
        <li>Probleme, die Schreibhand fest zu legen</li>
        <li>auffällige Stifthaltung, grobmotorischer Kraftgriff, drückt stark durch, Hand/Arm ermüden schnell</li>
        <li>Schwierigkeiten beim Schreiben lernen der Buchstaben und Zahlen</li>
        <li>Buchstaben- und Zahlendreher</li>
        <li>Richtungswechsel der Schrift</li>
        <li>Linien/Kästchen können nicht gut eingehalten werden</li>
        <li>Krakelige, unleserliche Schrift, unsauberes Schriftbild,</li>
        <li>Keine Lust zum Schreiben/Vermeidung</li>
        <li>Mal- oder Schreibblätter werden stark rechts bzw. links ausgerichtet</li>
        <li>Große Diskrepanz zwischen mündlichem und sprachlichem Ausdruck, Reihenfolgen können nicht gut eingehalten werden
            z.B. beim Aufsatz, Zahlenräume</li>
        <li>Lese-Rechtschreib-Schwäche</li>
        <li>Dyskalkulie</li>
    </ul>
    </p>
    <p>
        <b>Im emotional-sozialen und zwischenmenschlichen Verhalten:</b><br />
        <b>Reaktive Überlebensreaktionen und Primäremotionen:</b>
    <ul>
        <li>Aggressives Agieren: „situationsunangemessene Überreaktionen“ Wut, Schreien, Kreischen, Beißen, Schlagen, Boxen,
            Treten, Toben, Weinen, Gegenstände schmeißen, Türen knallen usw. (Kämpfen), Weglaufen (Flucht)</li>
        <li>Fehlinterpretation des Verhaltens Anderer in Bezug auf sich selbst, z. B. Aggression bei taktiler Berührung</li>
        <li>Will Situationen manipulieren und kontrollieren damit „Angst und Unsicherheit“ nicht erlebt wird</li>
        <li>Fällt auf als: Wortführer, Anstifter, Anführer, Rebell, Provokateur gegenüber Gleichalterigen, Eltern und
            Autoritätspersonen</li>
        <li>Ist der „Draufgänger“: risikofreudig, sucht immer wieder „Grenzerfahrungen“, waghalsig, zeigt
            „cooles“/überlegenes Auftreten, Ignoranz</li>
        <li>Kann sich schwer an Vereinbarungen und Regeln halten</li>
        <li>Verbissen/perfekt sein, Kontrolle, Zwang, gereizt, genervt, niedrige Frustrationstoleranz, Getrieben sein,
            Unruhe, „Kämpfer“ „Hyperaktivität“ (ADHS)</li>
        <li>Oder gegenteilige Reaktive Überlebensreaktion und Primäremotionen:</li>
        <li>„Situationsunangemessene Überreaktionen“ Trauer, Weinen, Angst, Verzweiflung, Panik, sich ungerecht behandelt
            fühlen, (beleidigter) „Rückzug“, sich verkriechen, verstecken (Flucht)</li>
        <li>Zurückhaltung oder Angst vor Neuem und Unbekannten, (Lern-und Alltagsherausforderungen/ Übernachtungen)
            Schwierigkeiten Entscheidungen zu treffen, mangelndes Durchhaltevermögen</li>
        <li>Irrationale Ängste/Panik, Trennungs- und Verlustängste, niedrige Frustrationsgrenze, Überforderungserleben,
            Schuldzuweisungen an andere </li>
        <li>Findet schwer Freunde, Außenseiterrolle, Isolation, Langeweile</li>
        <li>„Null-Bock-Haltung“ Energielosigkeit, aufgeben, Resignation „Verlorenheitsgefühl“ sich ungeliebt, nicht
            dazugehörig und wertlos fühlen, „Träumerle“ „Hypoaktiv“ (ADS)</li>
    </ul>
    </p>
    <p>
        Sally Goodard beschreibt, dass zwischen den Restreaktionen Frühkindlicher Reflexe und
        Krankheitsbildern/Körperreaktionen mögliche Zusammenhänge bestehen könnten, z.B.
    <ul>
        <li>Autismus, Formen von Autismus, z.B. selektiver Mutismus („Sprachverweigerung“ in bestimmten sozialen
            Kontexten), Asperger Syndrom</li>
        <li>Einnässen und Einkoten über das normale Alter hinaus</li>
        <li>ADHS/ADS</li>
        <li>Formen epileptischer Anfälle/Krampfanfälle</li>
        <li>plötzlicher Kindstod</li>
        <li>bestimmte Fälle von Anorexie und Bulimie</li>
        <li>Depressionen, Angst- und Panikstörungen, Zwänge, Süchte</li>
        <li>Nahrungsmittelunverträglichkeiten, Allergien</li>
        <li>Formen von Neurodermitis</li>
        <li>ungünstige Reaktionen auf Medikamente</li>
        <li>Schlechter Sauerstoffreflex: Infektionsanfälligkeit der Atemwege, Bronchialerkrankungen, Asthma</li>
        <li>Hypertonie (starke) oder Hypotonie (schwache) Muskelspannung</li>
        <li>Reaktive Hypoglykämie (Verminderung der Konzentration von Glukose im Blut)</li>
    </ul>
    </p>
    <p>
        Fast jeder Mensch findet sich mehr oder weniger, in den vorausgegangen Symptombeschreibungen zu den Restreaktionen
        frühkindlicher Reflexe und deren Langzeitfolgen
        wieder. Und fast jeder Mensch hat mit Hilfe der Fähigkeit, „kompensieren“ (die Symptome
        unbewusst ausgleichen) zu können, „gelernt“, damit zu leben.
        Und da dem Betroffenen nicht bewusst ist, dass in seinem Körper restaktive Reflexe vorhanden sind, werden viele
        „Blockaden, Handicaps und Unfähigkeiten“ als „das liegt mir
        nicht“, das kann ich nicht so gut, man muss auch nicht alles können, das war bei meiner
        Oma/Mutter auch schon so, ich habe eben einen „aufbrausenden Charakter“, ich kann eben
        nicht so gut mit Kritik umgehen, usw., als „normal“ und „gegebenes Schicksal/genetisches
        Erbe“, als nicht veränderbar, „hingenommen“… Leider, werden die „Auffälligkeiten“ oftmals
        als „elterliches und/oder schulisches Versagen“ bewertet, oder es wird darauf gehofft: „Das
        wächst sich schon von alleine raus“…! Aber in vielen Fällen, zeigen sich eben auch
        massive „anhaltende“ und vor allem „veränderungsresistente Probleme“ und diese werden
        vor allen Beteiligten „sehr deutlich“ wahrgenommen!
    </p>

    <h3>2. Beschreibung von Reflexabläufen und die Langzeitfolgen/Symptome bei
        anhaltenden (restaktiven) Frühkindlichen Reflexen </h3>

    <h4>2.1 Beschreibung des Uterinen Rückzugsreflexes</h4>

    <p>
        Entstehung: 5 – 7,5 Wochen im Mutterleib<br />
        Hemmung: 9. – 32. Woche im Mutterleib
    </p>

    <p>
        Der Embryo reagiert mit einer „amöbenähnlichen“ Reaktion auf äußere Reize, sobald er in
        der sensiblen Region der sich entwickelnden Oberlippe berührt wird. Diese auf Berührung
        empfindlichen Zonen weiten sich in ein paar Tagen auf die Handinnenflächen und
        Fußsohlen aus, bis schließlich die gesamte Körperoberfläche reagiert.
        Mit dem sich zunehmend entwickelnden taktilen Bewusstsein verschwindet das „Sich –
        Zurückziehen allmählich.
    </p>
    <p>
        Es wird vermutet, dass der Uterine Rückzugsreflex eine frühe Form des
        Furchtlähmungsreflexes ist.
    </p>

    <h5>Beschreibung</h5>
    <p>Kaada beschreibt die Merkmale des Furchtlähmungsreflexes, der auch Totstellreflex
        genannt wird, ähnlich der Reaktion eines Kaninchens, welches auf Bedrohung reagiert.
        Im Reich der Tiere hat er den Sinn, der Aufmerksamkeit des Feindes zu entgehen.
        Der Rückzugsreflex könnte als eine Antwort auf einen extremen Schreck oder als Schock
        betrachtet werden, der physiologische Reaktionen nach sich zieht.
    </p>
    <h5>Physiologische Reaktionen:</h5>
    <p>
    <ul>
        <li>Bewegungsunfähigkeit durch Lähmung</li>
        <li>Innerhalb dieser Bewegungslähmung kommt es zum Atemstillstand</li>
        <li>die Reaktion auf äußere Reize, sowie der Tonus der Muskeln sind herabgesetzt</li>
        <li>es tritt ein Mechanismus ein, der die Schmerzempfindung reduziert der</li>
        <li>Herzschlag wird verlangsamt</li>
    </ul>
    </p>
    <p>
        Beim Menschen stellt diese Reaktion eine Fehlanpassung dar, die situationsbedingt
        persönlich schlecht bewältigt wird.
        Diese Reaktion ist sozusagen die „Antwort“ auf „extrem empfundene Angst“ und wird
        zeitweilig von der Hirnrinde (cerebraler Cortex) aus gesteuert.
    </p>
    <p>
        Mit der Schaltung dieses Reflexes werden verschiedene Zusammenhänge in Bezug auf
        körperliche und emotionale/psychische Krankheitsbilder hergestellt.
    </p>
    <h5>Mögliche Auswirkungen</h5>
    <p>
    <ul>
        <li>Autismus</li>
        <li>selektiver Mutismus („Sprachverweigerung“ in bestimmten sozialen Kontexten)</li>
        <li>Asperger Syndrom</li>
        <li>Formen epileptischer Anfälle</li>
        <li>plötzlicher Kindstod</li>
        <li>bestimmte Fälle von Anorexie und Bulimie</li>
        <li>Depressionen, Zwänge, Ängste, Panik, Süchte, Persönlichkeitsstörungen</li>
        <li>Auswirkungen und Zusammenhänge bei Fortbestehen: <br />(die ich in der Arbeit mit Klienten/Kindern beobachtet
            habe)</li>
        <ul>
            <li>Schwierigkeiten, soziale Kontakte über „Kommunikation“ herzustellen, deshalb
                nicht selten, ein Leben in „Zurückgezogenheit“ bis hin zur „Isolation“</li>
            <li>in Gruppen, bei Vorträgen u. a. Situationen nicht sprechen können</li>
            <li>auf Anforderung z.B. in der Schule nicht antworten können, manchmal mit dem
                Empfinde „keine Luft zu bekommen“</li>
            <li>Bei Anforderungen, Herausforderungen, in Konfliktsituationen, die Tendenz, mit
                „Rückzug“ (beleidigt) zu reagieren</li>
            <li>Verstecken:</li>
            <ul>
                <li>unter dem Tisch (in der Schule) gerne unter einer Decke oder in eine Ecke</li>
                <li>ins Bett, oder unter das Bett kriechen oft in embryonaler Haltung nicht
                    ansprechbar, bis apathisch Frieren und Kältegefühl</li>
            </ul>
            <li> schlechtes Schlafen, oft durch Atemprobleme verursacht</li>
        </ul>
    </ul>
    </p>

    <p>
        Wenn Klienten aus höheren Zentren des Gehirns auf eine „Herausforderung“ mit dem
        Rückzugsreflex regieren, beschreiben sie folgendes Erleben: <b>„wie gelähmt zu sein“, „ich
            bekomme keine Luft“, „ich kann nicht gut durchatmen“, „ich würde mich am liebsten
            in einem Loch verkriechen“, „Ich fühle mich von meiner Umwelt wie abgeschnitten“</b>
    </p>

    <p>
        Wird dieser Reflex nicht rechtzeitig gehemmt, (die These ist, dass dieses im Mutterleib
        durch den Moro -Reflex stattfindet) bleibt er in höheren Zentren des Gehirns (subkortikale
        Ebene) aktiv und kann durch geringe Reize ausgelöst werden, was dann zu den voran
        beschriebenen Fehlanpassungen führen kann.
    </p>

    <h3>2.2 Beschreibung des Moro-Reflexes</h3>

    <p>
        Entstehung: 9. – 12. Woche im Mutterleib<br />
        Hemmung: 2. – 4. Monat nach der Geburt<br />
        Weiterentwicklung: Strauss-Reflex (reifer Schreckreflex; Startling - Phänomen)
    </p>

    <p>Er ermöglicht nach der Geburt den ersten Atemzug.</p>

    <p>
        Er ist wie alle frühkindlichen Reflexe ein Reaktionsmuster des Hirnstammes und hat die
        Aufgabe, das Überleben zu sichern.
        Mit zunehmender Bewegungsentwicklung und durch sichere soziale Beziehungskontakte
        formen, d.h. verknüpfen und vernetzen sich die höher gelegenen Gehirnareale. Diese sind
        das Säugergehirn, welches mit dem limbischen System unserer emotionales Gehirn
        darstellt und die beiden Großhirnhälften (Neokortex), die uns später insbesondere das
        Sprechen und die kognitiven Lernfähigkeiten ermöglichen.
    </p>

    <p>
        Der Moro-Reflex hat seinen Ursprung im Gleichgewichtsorgan. Der Reflex wird durch die
        „Veränderung der Raumlage des Körpers“, besonders durch die Bewegung des Kopfes
        ausgelöst. Unerwartete Reize führen zur Anregung des Innenohr und des sich darin
        befindenden Gleichgewichtsorganes, auch Labyrinth genannt.
    </p>
    <h4>Reizauslöser</h4>
    <p>
    <ul>
        <li>strukturell</li>
        <ul>
            <li>plötzliches Anstoßen an die Wickelunterlage</li>
            <li>bei mangelnder Abstützung des Kopfes, so dass der Kopf des Babys ins Leere
                fällt</li>
        </ul>
        <li>auditiv</li>
        <ul>
            <li>laute Geräusche</li>
        </ul>
        <li>visuell</li>
        <ul>
            <li>Veränderungen im Gesichtsfeld wie:</li>
            <li>plötzliche Bewegungen</li>
            <li>plötzlicher Lichtwechsel</li>
        </ul>
        <li>taktil</li>
        <ul>
            <li>Schmerz, Temperaturveränderungen</li>
            <li>unsanfte Berührungen</li>
        </ul>
    </ul>
    </p>
    <h4>Physiologische Reaktionen die auf den Reiz erfolgen</h4>
    <ul>
        <li>unmittelbare Erregung</li>
        <li>schnelles Einatmen, kurzes Erstarren oder Aufschrecken, gefolgt von Ausatmen
            meistens mit einem kurzer Aufschrei</li>
        <li>Kampf- oder Fluchtreaktion durch automatische Aktivierung des Sympathikus</li>
        <li>Freisetzung der Stresshormone Adrenalin und Kortisol</li>
        <li>Anstieg der Atemfrequenz, besonders in den oberen Lungenflügeln</li>
        <li>Beschleunigung des Blutdrucks und Rötung der Haut</li>
        <li>„Schreckpupillen“ (dienen der Erweiterung des Sehfeldes bei Gefahr)</li>
        <li>eventuell Ausbruch der Primäremotionen; Angst, Wut, Panik, Tränen</li>
    </ul>

    <p>
        Die vorab beschriebenen physiologischen Reaktionen sind so genannte Kampf-FluchtReaktionen und dienen der
        „Überlebenssicherung“.
        Steckt ein Mensch nun in den Reaktionen der „Überlebenssicherung“ eines fortbestehenden
        Moro-Reflexes fest, können sich nachfolgende Auswirkungen und Zusammenhänge zeigen.
        <b>Sally Goodard sagt, dass eine Person die im Moro-Reflex blockiert ist, erst reagiert
            (Hirnstamm) und dann denkt (Neokortex).</b>
    </p>

    <h5>Gleichgewicht und Koordination</h5>
    <ul>
        <li>Koordinationsstörungen in der Grob- und Feinmotorik</li>
        <li>körperliche Furchtsamkeit (Flucht, Ausschüttung von Noradrenalin) wie
            Unsicherheit beim Klettern und Balancieren weil das Gleichgewichtsystem leicht
            irritierbar bleibt</li>
        <li>„kennt keine Angst“, (Kampf, Ausschüttung von Adrenalin) ist z.B. waghalsig,
            kann Gefahren nicht einschätzen</li>
        <li>Abwehrhaltung oder Schutzreaktion wenn ein Ball geflogen kommt (plötzlicher
            Reiz im visuellen Gesichtsfeld)</li>
        <li>Fester Muskeltonus durch „Hab–Acht–Stellung“ (Dauerschaltung des SehnenSchutz-Reflexes)</li>
        <li>häufiges Stolpern über die eigenen Füße, (jede Bewegung des Kopfes verursacht
            eine automatische Mitbewegung des ganzen Körpers)</li>
        <li>Schwierigkeiten beim Erlernen von Fahrrad- u./o. Einrad fahren, Inliner usw.
            (Gleichgewicht)</li>
    </ul>

    <h5>kognitive Fähigkeiten bei Lernleistungen</h5>
    <p>in der Schule, Hausaufgaben (Arbeitsplatz)
    <ul>
        <li>Konzentrationsschwierigkeiten durch hohe Ablenkbarkeit bei Aufgaben und
            Lernherausforderungen, weil das System „reflexartig“ auf strukturelle, visuelle,
            auditive, und taktile Reize reagiert und nicht nach „Wichtig und Unwichtig“
            ausfiltern kann (Überladung kann Black out bewirken)
        </li>
    </ul>
    </p>
    <p>visuelle Auswirkungen:<br />
    <ul>
        <li>physiologische Reaktion der Pupillen auf Licht:</li>
        <ul>
            <li>bei sehr hellem Lichteinfall sollten sich die Pupillen zusammenziehen, um die
                einfallende Lichtmenge auf die Netzhaut zu reduzieren</li>
            <li>bei gedämpften Licht sollten sich die Pupillen erweitern, damit so viel Licht wie
                möglich auf die Netzhaut trifft.</li>
        </ul>
    </ul>
    </p>
    <p>
        Werden diese Funktionen durch einen beibehalten Moro-Reflex beeinträchtigt, kann es zu
        verschiedenen „Sehproblemen“ kommen:
    <ul>
        <li>z.B. beim Wechsel der Augen von Nah- auf Fernsicht beim Lesen und
            Abschreiben von der Tafel oder wenn aus dem Buch abgeschrieben wird</li>
        <li>schwarze Schrift auf weißem Papier verschwimmt (z.B. grelles Neonlicht in der
            Schule, die Pupillen ziehen sich nicht zusammen)</li>
        <li>generelle Lichtempfindlichkeit (häufiges Blinzeln, Augen reiben) und/oder
            schlechte Nachtsicht</li>
        <li>leichte Ablenkbarkeit durch:</li>
        <ul>
            <li>Gegenstände am Arbeitsplatz</li>
            <li>durch Bewegung des Lehrers, der Mitschüler u./o. Banknachbarn</li>
            <li>Bewegungen vor dem Klassenfenster</li>
        </ul>
    </ul>
    </p>
    <p>auditive Auswirkungen:</p>
    <ul>
        <li>Leichte Ablenkbarkeit durch eine Geräuschkulisse:</li>
        <li>Stimmen im Klassenraum</li>
        <li>durch plötzliche Geräusche, fallende Gegenstände</li>
        <li>Geräusche die von draußen kommen</li>
        <li>Alltagsgeräusche (Staubsaugen, laufende Spülmaschine, Rasenmäher) werden
            oft als sehr laut empfunden (Kind hält sich die Ohren zu und benennt, dass es
            ihm zu laut ist)</li>
        <li>Schwierigkeiten beim lautieren von Buchstaben und Wortlautverarbeitung bei
            Diktaten</li>

    </ul>

    <h4>Auswirkungen in der Physiologie</h4>
    <p>
        Die o. g. Faktoren führen in der Summe zu einer „Überladung des Neokortexes“
        (keine Ausfilterung der Sinnesinformationen) Dieser sendet nun Botschaften an
        die Nebennierendrüse und die Hypophyse. Diese registrieren das Signal und
        reagieren ihrerseits darauf indem sie die Stresshormone Adrenalin und Kortisol
        ausschütten. Beide gehören zu den Hauptabwehrstoffen gegen Allergien und
        Infektionen. Wenn die Nebennieren nun aber sozusagen, durch die dauernde
        „Hab-Acht-Stellung“ ständig angefordert sind, ermüden sie ziemlich schnell. Das
        führt zu weniger Produktion und die logische Konsequenz daraus ist, dass die
        Vorräte beider chemischer Substanzen mit der Zeit unzureichend sind. Durch
        diesen Zustand sind infektiösen Erregern, und körperschädigenden Giftstoffen,
        Türen und Tore in den Organismus geöffnet.
        Ein anderer Aspekt ist der, dass im Blut ein erhöhter Anteil von Adrenalin und
        Kortisol sein kann, was zusätzlich die „ ständig geschaltete Kampf-FluchtReaktion“ begünstigt und aufrechterhält.
        Durch die „Hab-Acht-Stellung“ die Vorbereitung der Kampf-Flucht-Reaktion
        befinden sich die Muskeln „in ständiger Arbeitshaltung“, wodurch der Blutzucker
        schneller verbrannt und ständig Glucose (Zucker) gebraucht wird.
    </p>
    <h5>Reaktionen und „Krankheitsbilder“</h5>
    <ul>
        <li>reaktive Hypoglykämie (abnormer geringer Zuckergehalt im Blut)</li>
        <li>Sucht nach Süßem (Essen)</li>
        <li>Hier kann ein Teufelskreislauf entstehen: Zuckerzufuhr (Nahrungszufuhr) = kurze
            Zeit Energie = Leistungsverbesserung, die zu kurzzeitiger Konzentration und
            geseigerten Durchhaltevermögen führt diese Energie verbraucht sich rasch und
            dadurch bedingt kommt es zu einem rapiden Leistungsabfall, der dann zu Unruhe
            und Aggressionsaufbau führt</li>
        <li>Verlangen nach Zucker (Nahrung) wird wieder aktiviert und der Kreislauf beginnt
            erneut</li>
        <li>Allergien und Immunschwächen, Nahrungsmittelüberempfindlichkeiten</li>
        <li>Hautekzeme, Hauterkrankungen, Formen von Neurodermitis, Pilzinfektionen</li>
        <li>ungünstige Reaktionen auf Medikamente (Penicillin)</li>
        <li>Anfälligkeit der Atemwege, häufige Infektionen im HNO – Bereich</li>
        <li>Bronchialerkrankungen, Asthma</li>
        <li>schlecht entwickelter CO² - Reflex, weil ein beibehaltener Moro-Reflex , das tiefe
            einatmen verhindert. Die Folge ist eine flache Atmung in den Lungenspitzen, die
            in Stresssituationen zu Hyperventilation führt. Diese wird als „Vorbote von Panik“
            betrachtet.</li>
        <li>schlechtes Durchhaltevermögen</li>
        <li>Hyper-, oder Hypoaktivität (ADHS/ADS)</li>
        <li>denkbar sind psychosomatische Beschwerden und Dispositionen für psychische
            Erkrankungen (Ängste, Panikanfälle, Zwänge, Suchterkrankungen,
            Persönlichkeitsstörungen)</li>
    </ul>
    <h5>Emotionaler und sozialer Bereich</h5>
    <p>Eine Person, die in dem Moro-Reflex blockiert ist, erlebt durch die ständige
        „sensorische Reizbelastung“ die Welt als laut, aggressiv und hell.
        Um dem Zustand von „Überladung“ zu entgehen, kann die Person eine
        Abneigung auf Veränderungen und Überraschungen entwickeln, was oft auf der
        Verhaltensebene als „schlechte Anpassungsfähigkeit“ gesehen wird.
        Zudem werden Stresshormone (Adrenalin und Cortisol) ausgeschüttet, die zur
        „Überlebensreaktion: „Kampf-Flucht-Verhalten“ führen und die sowohl,
        biochemische, sensorische als auch emotionale Abläufe beinhalten. Hiermit
        werden „psychosomatische Aspekte“ erklärbar.</p>

    <p>In diesem Kontext kann es zu verschiedenen Reaktionen im Erziehungs- und
        Beziehungsalltag kommen, z.B.
    <ul>
        <li>Beleidigt sein, schmollen, ignorieren, „arme Opferhaltung“, sich ungerecht
            behandelt fühlen, weinen, tiefe Traurigkeit, Rückzug, Weglaufen, (Flucht)</li>
        <li>mangelnde Fähigkeit, Entscheidungen zu treffen</li>
        <li>Angst oder Zurückhaltung vor Neuem und Unbekannten (Matheaufgaben,
            Übernachtungen, verreisen, in der /vor der Dunkelheit, usw.)</li>
        <li>Irrationale Ängste, Phobien</li>
        <li>Fehlinterpretation des Verhaltens anderer in Bezug auf sich selbst</li>
        <li>ambivalente Wahrnehmungsleistungen (z. B. Aggression bei taktiler Berührung)</li>
        <li>niedrige Frustrationsgrenze durch ständige Überforderung</li>
        <li>Gefühl der Unsicherheit aufgrund eines geringen Selbstwertgefühls und
            schwachen Egos</li>
        <li>Schickt andere vor</li>
        <li>Mitläufer</li>
        <li>Läßt sich durch Andere anstiften und überreden</li>
        <li>„Sündenbock“</li>
        <li>kann schlecht „Nein“ sagen und wird von anderen kommandiert und ausgenutzt</li>
        <li>„Träumerle“ mit wahrscheinlich schwachem Muskeltonus (eher der Zurückzieher/
            bzw. der, mit der „geistig-energetischen“ Haltung des „Totstellreflexes“:</li>
        <li>„Hat eh alles keinen Sinn (mehr)“</li>
        <li>beim Spazieren gehen „Bummelgang“, mag aber nicht gerne schnell und zügig
            laufen, „die Anderen“ sind ihm oft zu schnell. Diese Anpassungsschwierigkeit
            existiert unter Umständen auch im übertragenen Sinne im Verhalten und zeigt
            sich in verzögerten Reaktionen und in der Gefühlswelt</li>
    </ul>
    Oder
    <ul>
        <li>Abwehr, Verleugnen, Verantwortung auf andere abschieben, Wut, Toben,
            schimpfen, mit Gegenständen werfen, andere angreifen (körperlich/verbal)
            schreien (Kampf)</li>
        <li>will Situationen manipulieren und kontrollieren damit die eigene „unbewusste
            Unsicherheit“ nicht auffällt</li>
        <li>ist Wortführer</li>
        <li>ist Anstifter, Bestimmender, Anführer</li>
        <li>zeigt provozierendes Verhalten Eltern und Autoritätspersonen gegenüber</li>
        <li>ist „cool“ und zeigt überlegenes Auftreten</li>
        <li>ist der „Draufgänger“ (eher der Kämpfer mit starken Muskeltonus)</li>
        <li>kann beim Spazieren gehen „Rennen“, mag aber nicht gerne laufen, weil „die
            Anderen so lahm“ sind. Diese Anpassungsschwierigkeit existiert unter Umständen
            auch im übertragenen Sinne im Verhalten und zeigt sich in „Überreaktionen“ in
            der Gefühlswelt</li>
    </ul>
    </p>

    <h3>2.3 Beschreibung des Asymmetrisch Tonischer Nackenreflexes (ATNR)</h3>
    <p>
        Entstehung: 18. Schwangerschaftswoche<br />
        Hemmung: etwa 6 Monate nach der Geburt
    </p>

    <h4>Beschreibung des Reflexes</h4>
    <p>Der ATNR spielt in der Entwicklung von der Entstehung bis zum Zeitpunkt der Hemmung
        eine aktive Rolle.
        Bereits im Mutterleib nutzt das Baby diesen Reflex, der Bewegungen bahnt, den
        Muskeltonus entwickelt und das Gleichgewicht stimuliert, was wiederum dazu beiträgt,
        neuronale Verbindungen herzustellen.
        Bei der Geburt spielt er eine zentrale Rolle. Er sollte vollständig ausgereift sein, damit das
        Baby ihn für den Geburtsvorgang nutzen kann.
        Wenn die Austreibungsphase beginnt, sollten Mutter und Kind aktiv als Team
        zusammenarbeiten. Der ATNR hilft dem Baby, sich im Rhythmus der Wehen durch den
        Geburtskanal nach unten zu schrauben.
        Die aktive Teilnahme des Kindes am Geburtsprozess stärkt und festigt den ATNR, so dass
        er in den ersten Monaten für seine Entwicklungsfunktionen etabliert ist.
        (Hier treten alle Geburtsvorgänge in Erscheinung, die dem Kind und der Mutter die
        „Teamarbeit verwehren“ (Kaiserschnitt, PDA, rausdrücken, Wehen fördern - Wehen
        hemmen, herausdrücken, u. ä.).
        In den ersten Lebensmonaten sorgt der ATNR dafür, dass die Atmung gesichert ist, wenn
        das Baby auf dem Bauch liegt.
        Zudem stellt er die erste Bewegung der Hand- Augen-Koordination da. Das Kind trainiert
        über diesen Reflex sozusagen das „Bewusstsein für die homolaterale Körperseitigkeit“. Hat
        das ausreichend stattgefunden ist es an der Zeit, dass das kindliche System den nächsten
        Entwicklungsschritt in die „Lateralität“ macht.</p>

    <h4>Physiologische Reaktion</h4>
    <p>Dreht das Baby den Kopf zu einer Seite, führt das zum Ausstrecken des Armes und Beines
        zu der selbigen, während die Gliedmaßen auf der anderen Seite in die Beugung gehen.
        Zusätzlich öffnet sich die Hand auf der Seite, die in die Streckung geht.
    </p>

    <h4>Reaktionen Auswirkungen bei Fortbestehen:</h4>
    <h5>Gleichgewicht und Koordination</h5>
    <h6>in Bewegung, Haltung, Sport und Freizeit</h6>
    <ul>
        <li>kann allgemein zu Gleichgewichtsproblemen führen (schon bei Kopfdrehung)</li>
        <li>die Reflexbewegung kann das fließende Kriechen und Krabbeln im
            Überkreuzmuster verhindern</li>
        <li>dadurch wird wiederum die weitere Entwicklung der Koordination von Händen und
            Augen beeinträchtigt</li>
        <li>die Integration verschiedener Sinneswahrnehmung mit dem
            Gleichgewichtssystem findet nur unzureichend statt</li>
        <li>die Myelisierung des ZNV (höhere kognitive Fähigkeiten) kann durch mangelnde
            Bewegungserfahrung des lateralen Musters unzureichend sein</li>
        <li>das Gehen und die Bewegung ist nicht fest im Überkreuzmuster etabliert</li>
        <li>homolateraler, roboterartiger Gang</li>
        <li>beim Sport werden Werf- und Kickbewegungen unbeholfen wirken</li>
        <li>Schwierigkeiten bei Bock- und Weitsprung (Hänseleien durch Andere)</li>
        <li>generelle Einschränkung für „Tätigkeiten, die ein Überschreiten der Mittellinie
            erfordern</li>
        <li>das Greifen eines Gegenstandes gelingt, aber nicht die Handhabung mit beiden
            Händen (Festhalten eines Papiers und Schneiden mit der anderen)</li>
    </ul>
    <h6>kognitive Fähigkeiten in Lernleistungen in der Schule, Hausaufgaben
        (Arbeitsplatz)</h6>
    <ul>
        <li>Konzentrationsprobleme durch nachfolgende Einzelaspekte:</li>
        <li>das Anlegen der Präferenz (Dominanz) des Gehirns und der Sinnesorgane
            automatisiert sich nicht; also wechselnde Lateralität (Chaos im System)</li>
        <li>Unsicherheit in rechts/links Unterscheidung</li>
        <li>keine Dominanz in der Händigkeit (Ambidexter, Beidhändigkeit)</li>
        <li>Schwierigkeiten beim Schrifterwerb</li>
    </ul>

    <h6>Visuelle Auswirkungen</h6>
    <ul>
        <li>Die unsichtbare Barriere des homolateralen ATNR Musters, betrifft das
            Überqueren des visuellen Mittefeldes im gleichen Maß, wie die anderen
            Körperbewegungen.</li>
        <li>die Bewegungen der Augen sind betroffen, da das Kind im Bereich des
            Mittelfeldes stimulusgebunden bleibt, was heißt, es ist unfähig, irrelevante
            Sinneseindrücke auszufiltern und zu ignorieren.</li>
        <li>Verfolgen der Zeilen nicht möglich durch Blicksprung</li>
        <li>Leseschwierigkeiten</li>
    </ul>

    <h6>Auditive Auswirkungen</h6>
    <ul>
        <li>Irritationen der Leistung des wahrnehmenden Hörens; fehlerhafte Interpretationen
            von dem was gehört wird</li>
        <li>dem Gehörten können keine Buchstaben und Symbole zugeordnet werden</li>
        <li>Ambivalenz in der Hör-Lateralität (Ohr wechselt)</li>
    </ul>
    <h6>Sprache</h6>
    <ul>
        <li>Beim (schnellen) Sprechen Verwechslung von Wortfragmenten
            (Dysgrammatismus); aus, „Klapperschlange“ wird „Schlangerklappe“
        </li>
    </ul>

    <h6>Schreiben</h6>
    <ul>
        <li>Die beibehaltene Reflexbewegung veranlasst die Kopfdrehung in die
            Schreibrichtung, den Arm auszustrecken und die Hand zu öffnen. Die
            gegenüberliegende Seite geht in die Beugung, das heißt, das Kind wird mit großer
            Sicherheit, den Kopf mit der Hand abstützen. (permanent Ermahnungen) Das
            Kind kann nur mit enormem, (unbewussten) Kraftaufwand diese unsichtbare
            Macht kompensieren. Das Kompensieren wirkt wiederum ungünstig auf die
            kognitiven Fähigkeiten aus.</li>
        <li>graphomotorische (schreibmotorische) Probleme, schlechte Handschrift</li>
        <li>ungewöhnliche Stifthaltung, verkrampfte Stifthaltung, starkes Drücken beim
            Schreiben</li>
        <li>Schrift geht auf gleicher Seite in verschiedene Richtungen</li>
        <li>Heft wird um 90° gedreht, ein Versuch, sich mit dem Reflex zu arrangieren</li>
        <li>Abspeichern von Wörtern in verschiedenen Schreibweisen, Druck-, und
            Schreibschrift gemischt, oder/und</li>
        <li>Vermischen von kleinen und großen Buchstaben innerhalb eines Wortes</li>
        <li>(ambivalenter Gehirnzugang/Chaos)</li>
        <li>freies Schreiben (Aufsätze, Geschichten)</li>
        <li>Fantasien und eigene Gedanken zu fixieren fällt schwer, da das Schreiben große
            Kompensation erfordert, was sich wiederum auf die „Schreibkonzentration“
            auswirkt</li>
        <li>Buchstaben und Zahlendreher</li>
        <li>Mathe, Geometrie, Formen, gerade Linien erkennen und darstellen, besonders
            symmetrische Figuren (zeichnen) fällt schwer</li>
        <li>generelle Diskrepanz zwischen dem mündlichen und schriftlichen Ausdruck</li>
    </ul>

    <h3>2.4 Beschreibung des Tonischen Labyrinthstellreflexex (TLR)</h3>
    <p>
        Tonischer Labyrinthstellreflex vorwärts<br />
        Entstehung: Im Mutterleib – fötale Beugehaltung<br />
        Hemmung: Ca. 4 Monat nach der Geburt
    </p>
    <p>
        Tonischer Labyrinthstellreflex rückwärts<br />
        Entstehung: Bei der Geburt<br />
        Hemmung: Findet im Prozess statt, der mit ca. 6 Wochen beginnt und sich etwa bis zum 3.
        Lebensjahr vollzogen hat. Gleichzeitig findet die Entwicklung der „Brücken“ - Reflexe und
        der Kopfstellreflexe, die zu den Stell- und Haltereflexen gehören, statt.
    </p>

    <h4>Beschreibung des Reflexes</h4>
    <p>In den ersten Lebensmonaten besteht zwischen dem Moro-Reflex und dem Tonischen
        Labyrinthstellreflex eine enge Verbindung. Beide Reflexe haben ihren Ursprung im
        Gleichgewichtsorgan (Vestibularapparat) und reagieren durch die Anregung des Innenohrs
        (Labyrinth). Das heißt, jede Bewegungsveränderung der Körperposition im Raum aktiviert
        beide Reflextätigkeiten.
        Das Geborenwerden ist mit einer ganzen Reihe von neuen Herausforderungen verbunden,
        die das Baby in Bezug auf die Schwerkraft, im Mutterleib nur in abgeschwächter Form
        erfahren hat.
        Das Vorhandensein dieses Reflexes stellt für das Kind eine erste einfache Methode dar, mit
        dem Gesetz der Schwerkraft umzugehen.
        Der TLR sollte in beiden Richtungen zum Zeitpunkt der Geburt voll entwickelt sein.
        Er hat für das Neugeborene einen tonisierenden (spannenden, dehnenden) Einfluss auf die
        Muskelspannung im ganzen Körper. Der TLR hilft dem Baby, sich aus der bisherigen
        fötalen Beugehaltung gerade zu strecken, was wiederum das Gleichgewicht, den
        Muskeltonus und die Propriozeption (Tiefensensibilität) aktiviert und trainiert.
        Mit sechs Monaten sollte sich dann allmählich die Kopfkontrolle, der Augen- und
        Labyrinthstellrefkex entwickeln. Hier entwickelt sich das „zephalo - kaudale“ Gesetz,
        welches eine Bewegung beschreibt, die sich vom Kopf, bis nach unten zu den Zehen
        vollzieht. Das bedeutet, Kopfkontrolle, Gleichgewicht und Muskelspannung sind die
        Grundlagen aller Frühformen der Bewegung.
    </p>

    <h4>Physiologische Reaktion:</h4>
    <p>
        Der TLR wird durch die Bewegung des Kopfes sowohl nach vorne, als auch nach hinten
        ausgelöst. Der Kopf befindet sich bei Beugung nach vorne über der Ebene des Rückgrats
        und nach hinten unter dieser Ebene. Die letztere Bewegung führt unmittelbar dazu, dass
        das Baby, Arme und Beine ausstreckt.

    <ul>
        <li>Durch die Irritation des Gleichgewichtszentrums, entsteht für das Kind kein fester
            räumlicher Bezugspunkt.</li>
        <li>Bei einem fortbestehenden TLR wird es schwer, Wahrnehmungsempfindungen in
            Bezug auf Raum, Entfernung, Tiefe und Geschwindigkeit einzuschätzen</li>
        <li>Der STNR bleibt ebenfalls erhalten, weil er den TLR nicht zu hemmen vermag.
            Solange der TLR aktiv ist, verändert jede Kopfhaltung den Muskeltonus, was
            Auswirkungen auf das Kriechen und Krabbeln hat</li>
        <li>In diesem Entwicklungsabschnitt, wird der „Sinn von Balance erworben.
            Sensorische Informationen werden zum ersten Mal synchronisiert, um dem Kind
            ein erweitertes und vollständigeres Bild der Umwelt zu liefern</li>
        <li>Wenn das Kind zu laufen beginnt, wird es schwer sein, sich gegen die
            Schwerkraft zu bewegen.</li>
        <li>Neben der Kopfhaltung, gibt es weitere wichtige Faktoren, nämlich dass sich der
            Vestibularapparat und die Augen, innerhalb des gleichen Schaltkreises befinden.</li>
        <li>Infos aus dem Körper gelangen zu den Vestibularkernen, werden zu den Augen
            weitergeleitet</li>
        <li>Infos von den Augen wiederum passieren die Vestibularkerne und gelangen dann
            zu den Propriozeptoren (Sinneszellen in den Muskeln), was wiederum
            Auswirkungen auf die Augen hat</li>
    </ul>
    </p>

    <h4>Reaktionen und Auswirkungen TLR <b><u>vorne</u></b></h4>
    <h5>auf Gleichgewicht und Koordination</h5>
    <p>in der Bewegung, Haltung, Sport und Freizeit
    <ul>
        <li>generelle Abneigung gegen sportliche Aktivitäten</li>
        <li>Schlechte Haltung – krummer Rücken</li>
        <li>Überstreckung des Kopfes beim Schwimmen (lernen)</li>
        <li>Hypotonie (langes Stehen kann schwer fallen)</li>
        <li>Sitzposition</li>
        <li>Arme sind auf dem Tisch und stützen den Kopf, die Beine befinden sich
            ausgestreckt unter dem Tisch</li>
        <li>Füße um die Stuhlbeine gewickelt</li>
        <li>Das Schwimmen lernen wird durch die Reflexbewegung beeinflusst, der Kopf
            über Wasser macht es schwer, die Bewegungen zu synchronisieren</li>
        <li>das Schwimmen unter Wasser fällt leichter, da dort die Schwerkraft ganz
            aufgeben wird</li>
    </ul>
    </p>
    <h5>auf kognitiven Fähigkeiten und Lernleistungen</h5>
    <p>in der Schule, bei den Hausaufgaben, (Arbeitsplatz), im Alltag
    <ul>
        <li>Schwächen im Erkennen und Einhalten von Abfolgen (klein, kleiner, am Kleinsten
            Bildgeschichten)</li>
        <li>schwaches Zeitgefühl (kennt die Uhr nicht/ablesen fällt schwer)</li>
        <li>auditive Auswirkungen</li>
        <li>Probleme mit der Geräuschlokalisation</li>
        <li>visuelle Auswirkungen</li>
        <li>Wahrnehmung des Sehens eingeschränkt</li>
        <li>Orientierungsprobleme/Raumwahrnehmung</li>
        <li>Straßenkartenlesen, fremde Orte und Gegenden</li>
        <li>Problem mit der „Figur-Grund-Unterscheidung“</li>
        <li>Die Unterscheidung von zwei sich wieder sprechenden visuellen Informationen ist
            schwierig (trennen und ordnen) (Höhenangst, Schrift und Papier, Formen im
            Raum/auf Papier = oben, unten, neben)</li>
    </ul>
    </p>
    <p>Mathe
    <ul>
        <li>braucht Finger oder andere Hilfen zum Abzählen, das abstrahieren von
            Zahlenbildern, zu von Zahlenwerten, Zahlenräumen und Mengen ist schwer
            möglich</li>
        <li>Einhalten von Abfolgen fällt schwer, Probleme mit Vorgänger und Nachfolger</li>
        <li>kein sicheres Rechnen in den Zahlenräumen (10er,100er,u.s.w)</li>
        <li>Geometrie, das Erkennen von Raum - Lage- Beziehungen ist gestört</li>
    </ul>
    </p>

    <p>Schreiben
    <ul>
        <li>Probleme beim Einhalten von Schreiblinien und Kästchen je kleiner, desto
            schlimmer</li>
        <li>Buchstaben und Zahlendreher</li>
        <li>Abschreiben von Tafel/Heft zu Heft ist problematisch</li>
        <li>Beim Schreiben wird die Richtung gewechselt, d. h. Buchstaben und Wörter
            neigen sich mal nach links, mal nach rechts</li>
    </ul>
    </p>
    <p>Aufsätze
    <ul>
        <li> Reihenfolgen können nicht eingehalten werden z.B. beim Nacherzählen, bei
            Bildergeschichten, beim strukturellen Aufbau von Einleitung, Höhepunkt, Ende
            fehlerhaft</li>
    </ul>
    </p>
    <p>Lesen
    <ul>
        <li>Zeilen verschwimmen, Zeile geht verloren</li>
        <li>Binokulares (beidäugiges) Sehen erschwert</li>
    </ul>
    </p>
    <p>
        Malen
    <ul>
        <li>wird vermieden, über Begrenzungen, stricheliges (krakeliges) Malen</li>
        <li>Richtungen werden vertauscht (auf dem Kopf gemalt)</li>
    </ul>
    </p>
    <p>
        Sprache
    <ul>
        <li>Satzstellungen werden vertauscht: „Heute ist ein schöner Tag“ „Schöner Tag ist
            heute“ (Dysgrammatismus)</li>
    </ul>
    </p>

    <h4>Reaktionen und Auswirkungen TLR <b><u>hinten</u></b></h4>
    <h5>Gleichgewicht und Koordination</h5>
    <p> in der Bewegung und Koordination
    <ul>
        <li>generell schlechte Balance und Koordination</li>
        <li>Reiseübelkeit</li>
        <li>Höhenangst</li>
        <li>Schlechte Körperhaltung</li>
        <li>Zehenspitzengang</li>
        <li>hohe Körperspannung, dadurch eher ruckartige Bewegungen weil die
            Streckmuskeln größeren Einfluss ausüben als die Beugemuskeln (Hypertonie)</li>
    </ul>
    </p>
    <h5>bei kognitiven Fähigkeiten und Lernleistungen in der Schule, Hausaufgaben (Arbeitsplatz)</h5>
    <ul>
        <li>s.o. wie beim TLR vorne</li>
        <li>schwache Organisation (Durcheinander in dem Tornister und im Kinderzimmer)</li>
    </ul>
    <h5>visuelle Auswirkungen</h5>
    <p>s.o. wie beim TLR vorne</p>
    <h5>auditive Auswirkungen</h5>
    <p>s.o. wie beim TLR vorne</p>
    <h5>Emotional/Soyial</h5>
    <ul>
        <li>auf Grund der vielen Probleme und Herausforderungen, die nicht bewältigt
            werden, erlebt ein Betroffener u.U. Wut und Hilflosigkeit; Trauer und Verzweiflung; Resignation und Ängste</li>
        <li>mangelndes Selbstwertgefühl und Selbstbewusstsein</li>
        <li>Außenseiterposition, usw.</li>
        <li>ungeeignete Lernanforderungen und Sanktionen (Nachsitzen, Nacharbeiten,
            ständiges Wiederholen ohne Erfolg) verstärken hier das tiefe Gefühl von
            „Unzulänglichkeit“</li>
    </ul>
    <h5>Physisch</h5>
    <p>auf Grund der vielen Probleme und Herausforderungen, die nicht bewältigt werden, können
        körperliche Begleiterscheinungen die Folge sein, z.B.:
    <ul>
        <li>Kopfschmeren</li>
        <li>Bauchschmerzen mit und ohne Übelkeit</li>
        <li>trotz ausreichendem Schlaf: große Abgeschlagenheit, keine Energie, keine Kraft
            Dauermüdigkeit oder Schlafprobleme</li>
        <li>Höhenangst</li>
    </ul>
    </p>

    <h3>2.5 Beschreibung des Symmetrisch Tonischen Nackenreflexes (STNR)</h3>
    <p>Entstehung: 6. – 8./9.Monat<br />
        Hemmung: 9. –11. Monat</p>

    <h4>Beschreibung des Reflexes</h4>
    <p>
        Der STNR hilft dem Kind weiter in seiner Entwicklung, die Schwerkraft zu bewältigen.
        Er ist ein so genannter Brückenreflex, zwischen den frühkindlichen, die gehemmt werden
        müssen und den lebenslangen Haltungsreflexen.
        Möglicherweise ist er kein echter Reflex, sondern nur eine entscheidende Phase des TLR.
        Fest steht, dass er die Hemmung des TLR unterstützt und maßgeblich an dem Initiieren der
        nächsten Bewegungsform beteiligt ist, dem Kriechen und Krabbeln.
        Er ermöglicht die Haltung der „Vierfüßerposition“ einzunehmen, verhindert aber noch eine
        Vorwärtsbewegung in dieser Haltung.
        Der STNR teilt den Köper an der „horizontalen Mittellinie“ durch seine Streck-, und
        Beugebewegung.
        Es wird vermutet, dass der STNR in seiner Bewegung über das Heben und Senken des
        Kopfes, die Augen auch auf Nah, - und Fernsicht trainiert.
        Es wird auch angenommen, dass das Hin- und Herschaukeln der Kinder das
        Bewegungstraining ist, welches den Reflex hemmt kurz bevor sie zu krabbeln beginnen.
        Der STNR hilft, die Zusammenarbeit zwischen dem Kreuzbein- und Hinterhauptbereich zu
        synchronisieren. Diese Fähigkeit, ist unumgänglich für die nachfolgende Fortbewegung auf
        „vier“ und zwei Beinen.

    <ul>
        <li>Bleibt der STNR im System anwesend, wird er das Kind in eben diesen
            Fortbewegungen</li>
        <li>beinträchtigen, statt krabbeln geht es im „Bärengang“, rutschst über den Po oder
            lässt das Krabbeln einfach aus.</li>
        <li>Das Krabbeln stellt, wie ja hinreichend bekannt ist, aber eine wesentliche
            Grundlage für die Festigung des neuronalen Überkreuzmusters dar.</li>
        <li>Besonders die Hand Augen-Koordination ist betroffen, das Krabbeln trainiert
            diese Fähigkeit, weil das Kind im Bewegungsablauf, die Augen über die Mittellinie
            auf die Hände fokussiert</li>
        <li>Also wird es auch hier in einer Kette zu mangelnder Körper-, Gleichgewichts- und
            Sinnesintegration kommen, die sich später auf Bewegung, Lernen und Verhalten
            auswirkt.</li>
    </ul>
    </p>
    <h4>Physiologische Reaktion</h4>
    <p>Aus der „Vierfüßerposition“ hinaus, führt das Beugen des Kopfes zu einer Beugung der
        Arme und Streckung der Beine, mit Anheben des Pos.
        Das Heben des Kopfes führt zu einer Beugung der Beine und zur Streckung der Arme,
        mit Absenken des Pos auf die Fersen.</p>

    <h5>Gleichgewicht und Koordination</h5>
    <p>
        in der Bewegung, Haltung, Sport und Freizeit
    <ul>
        <li>schlechte Haltung</li>
        <li>Zusammensacken beim Sitzen, besonders am Tisch oder Schreibtisch (Kind liegt
            „spätestens“ am Ende der Schulstunde auf dem Tisch)</li>
        <li>Beim Sitzen auf der Erde wird gerne die „W“ Beinhaltung eingenommen</li>
        <li>affenähnlicher Gang</li>
        <li>es wird sich nicht gerne sportlich betätigt</li>
        <li>Ballspiele können eine Katastrophe sein, weil das Kind den Ball aus dem Blick
            verliert. Es kann ihn möglicherweise, wenn er dann in den Nahsichtbereich
            gelangt nicht mehr fangen/spielen (überrascht)</li>
        <li>schlechte Hand Augen-Koordination führt z.B. zum Kleckern beim Essen</li>
        <li>Tollpatschigkeit (Gegenstände fallen lassen, umstoßen, anrempeln)</li>
        <li>Zappelphilippsyndrom (Kind versucht „unbewusst“ ständig das vom Stuhl
            rutschen zu vermeiden)</li>
    </ul>
    </p>
    <h5>bei kognitiven Fähigkeiten und Lernleistungen</h5>
    <p>in der Schule, bei den Hausaufgaben, (Arbeitsplatz), im Alltag</p>
    <p>visuelle Auswirkungen
    <ul>
        <li>Schwierigkeiten beim Wiederherstellen des binokularen Sehens</li>
        <li>Nah- und Fernsicht</li>
        <li>Schreiben</li>
        <li>kann nur langsam abschreiben (Augen stellen sich nicht ausreichend auf NahFern / oben-unten um)</li>
        <li>im unteren Abschnitt des Geschriebenen auffällige Häufung von Fehlern</li>
        <li>Konzentration wird durch die Reflexbewegung abgelenkt (Zappelphilipp)</li>
    </ul>
    </p>
    <p>Lesen
    <ul>
        <li>Zeilenwechsel (oben/unten) schrierig (verrutschen)</li>
        <li>Fehlerlesen (binokulares Sehen nicht gewährleistet)</li>
    </ul>
    </p>
    <p>Mathe
    <ul>
        <li>Probleme beim Addieren und Subtrahieren von Zahlenkolonnen, weil die
            vertikalen (senkrecht) Augenbewegungen beeinträchtigt sind</li>
    </ul>
    </p>
    <p>Emotional/Sozial: siehe TLR</p>
    <p>Dieser Reflex wird auch mit dem ADHS/ADS Syndrom in Verbindung gebracht.
        Im Bender-Institut führten O’Dell und Cook 1996 ein Reflexhemmungsprogramm für den
        STNR durch. Die Betroffenen beider Gruppen machten große Fortschritte.</p>

    <h3>2.6 Weitere Frühkindliche Reflexe deren Fortbestehen sich über Symptome zeigen:</h3>
    <h4>Der Such- und Saug- und Schluckreflex:</h4>
    <ul>
        <li>Sprachprobleme und/oder Sprachverzögerung</li>
        <li>Überempfindlichkeit im Mund- und Lippenbereich</li>
        <li>Verweigerung von fester/harter Nahrung die gutes Kauen bedarf</li>
        <li>Vorliebe für Nahrung die gut einzuspeicheln ist und eine breiige Konsistenz hat.</li>
        <li>Schluckbeschwerden</li>
    </ul>
    <h4>Der Spinale Galantreflex</h4>
    <ul>
        <li>„Herumzappeln“</li>
        <li>mangelnde Konzentration</li>
        <li>schwaches Kurzzeitgedächtnis</li>
        <li>Einseitige Hüftrotation beim Gehen</li>
        <li>abnormale Krümmung der Wirbelsäule</li>
        <li>Bettnässen</li>
    </ul>
    <h4>Der Handgreifreflex (Palmer-Reflex)</h4>
    <ul>
        <li>Schreiben und Malen werden von Mund- und Zungenbewegungen begleitet (kann
            bis ca. 8 Jahre normal sein)</li>
        <li>Sprachschwierigkeiten (Such- und Saugreflex hängen mit dem Handgreifreflex
            zusammen)</li>
        <li>überempfindliche Handinnenfläche</li>
        <li>möglich fehlender „Pinzettengriff“ – kann zu Folge haben, dass die Stifthaltung
            ungünstig beeinflusst wird</li>
        <li>Geringe mangelnde Geschicklichkeit, da der Reflex die unabhängige Bewegung
            (Opposition) des Daumen zu den anderen Fingern verhindert</li>
    </ul>

    <h2>3. Auswirkungen von anhaltenden Frühkindlichen Reflexen</h2>

    <p>Die Betroffenen sind im Verhalten und im Lernen oft anders und empfinden dies auch so:
        „Irgendetwas ist bei mir anders als bei den anderen“; „ Ich bin nicht in Ordnung“. Das stellen
        sie für sich selber fest, aber durch ihre oft gebündelten Problematiken, wird die Umwelt
        auch nicht müde, sie auf ihre „Defizite“ und ihr „ständiges Fehlverhalten“ hinzuweisen. Auch
        wenn es nicht immer deutlich wird, aber diese Kinder, versuchen alles, (bevor sie ggf.
        resignieren und aufgeben oder zum (aggressiven) Rebellen werden) sich den gegebenen
        Anforderungen anzupassen</p>
    <p>Das liegt in der Natur des Menschen. Dieser ist ein Herdentier und will dazu gehören.
        Manchmal gelingt es unter Aufbietung aller Kräfte, sich den gegebenen An- und
        Herausforderungen anzupassen, aber meistens ist das nur für eine kurze Dauer möglich.
        Der unwillkürliche Reflexbereich, zusätzlich noch an negative Gefühle gebunden und in
        destruktiven bewussten u./o. unbewussten Glaubenssätzen verankert (Ich kann das nicht.
        Ich bin sowieso zu blöd. Keiner mag mich usw.) hat eine stärkere Macht, als der Wille
        vermag, konstant durchzuhalten.
    </p>
    <p>
        Und hier fängt ein Teufelskreis von verstärktem Bemühen, Versprechungen und Vorhaben
        an, die dann doch wieder in die Erfahrung münden, den Anforderungen im wahrsten Sinne
        des Wortes, „nicht gewachsen“ zu sein und damit zu versagen! Weder das Kind, noch seine
        Umwelt finden oftmals eine plausible Erklärung dafür, was mit ihm und in ihm passiert.
        Dieser Teufelskreislauf ist bei Erwachsenen von gleicher Bedeutung und zieht sich durch ihr
        ganzes Leben.
        Ich habe zahlreiche Beschreibungen für diesen Zustand „zu wollen und nicht zu können“
        von Kindern, Jugendlichen und Erwachsenen gesammelt.
    </p>
    <p>
        Sie alle haben negative/traumatische Erfahrungen während der Schwangerschaft und/oder
        bei ihrer Geburt erlebt. Nicht selten hat sich in der Biografie- und Genogrammarbeit
        herausgestellt, dass es bereits bei den Eltern/Großeltern Themen zu Schwangerschaft und
        Geburt gab, die aus heutiger Sicht als Schock und traumatische Ereignisse gelten würden.
        Hier kann das Phänomen auftreten, dass sich diese „Ahnenerlebnisse“, da sie meistens
        unverarbeitet bleiben, auf die nachfolgenden Generationen auswirken können.
    </p>
    <p>
        Die nachfolgenden Aussagen von Menschen aus meiner Praxisarbeit, lassen deutlich
        werden, was es bedeuten kann, von „anhaltenden frühkindlichen Reflexen“ beeinflusst zu
        sein.
    </p>
    <p>
        <i>„In mir sind zwei Männchen, das böse für wütend und das liebe für artig.“
            (Julian, 5 Jahre)</i>
    </p>
    <p><i>„In mir gibt es einen Guten und einen Bösen.“ (Andreas, 13 Jahre)</i></p>
    <p><i>„Es fühlt sich so an, als ob ich immer schon gegen zahlreiche unsichtbare Windmühlen in
            mir ankämpfen musste.“ (Heinz, 42 Jahre)</i></p>
    <p><i>„Manchmal denke ich, es gibt da noch jemanden in mir, der mich steuert.“
            (André, 14 Jahre)</i></p>
    <p><i> „Ich habe einen Wurm in mir, der zwischen meinem Bauch und meinem Kopf hin- und
            herwandert. Der sorgt im Bauch dafür, dass ich Angst habe und anschließend im Kopf
            ausraste und in der Schule nicht lernen kann.“ (Jana, 12 Jahre)
        </i></p>
    <p><i>„Es gibt Tage, da unterliege ich einer destruktiven Macht. Ich stehe neben mir und schaue
            meinem wütenden Agieren hilflos zu, ohne eine Möglichkeit für mich zu sehen aus diesen
            Situationen aussteigen zu können.“ (Maria, 40 Jahre)
        </i></p>
    <p><i>„Egal, wie oft ich mir vornehme ruhig zu bleiben, die Wut explodiert schneller, als dass ich
            sie stoppen kann.“ (Marvin, 15 Jahre)</i></p>
    <p><i>„Mein Sohn ist manchmal wie ferngesteuert, so als ob in ihm, etwas über ihn die Kontrolle
            übernimmt.“ (Mutter von Arne, 12 Jahre)</i></p>
    <p><i>„Es ist total „scheiße“, etwas zu wollen, z. B. gute Noten zu schreiben, nicht zu ärgern, nicht
            zu stören und nicht auszurasten - und es nicht „gescheckt“ zu kriegen, null Ahnung zu
            haben, wie das geht. Und noch schlimmer ist, keiner glaubt mir, dass ich es wirklich anders
            machen will. Ich denke ich habe die totale „Arschkarte“ und fühl mich wie ein verdammter
            Loser.“ (Max 17 Jahre)
        </i></p>
    <p><i>„Unser Kind ist wie „gespalten“ - ein brillanter Geist in dem Körper eines „Riesenbabys“.
            (Eltern von Jonas, 10 Jahre)
        </i></p>
    <p><i>„Ich würde mich total freuen, wenn ich mich endlich trauen würde, von einem Sprungbrett
            oder vom Startblock ins Wasser springen zu können.
            Wenn ich den Kopf nach unten mache, wird mir irgendwie komisch im Kopf. Dann kriege ich
            wackelige Beine und irgendwie habe ich Angst ins Wasser zu fallen - und mir weh zu tun -
            weil ich nicht weiß, wie ich aufkomme“. (Lena, 11 Jahre)</i></p>
    <p><i>„Ich will ja immer schön schreiben und die Buchstaben sollen auch immer alle in eine Reihe,
            ich kann aber „nix“ – „gar nix“ dafür, die blöden Buchstaben hauen mir immer ab.“
            (Jonas, 9 Jahre)</i></p>
    <p><i>„Meine Lehrerin sagt mir immer, ich muss mich mehr anstrengen und ich muss mehr üben,
            und ich müsste es wirklich lernen wollen, dann klappt das Lesen schon. Die hat ja keine
            Ahnung, wie gerne ich lesen will, - aber erst, wenn ich weiß, wie das geht.“ (Fabian 10
            Jahre)</i></p>

    <p>Die Arbeit mit diesen Menschen hat mir, auf Grund der positiven Veränderungen gezeigt,
        wie wichtig es ist, die Restreaktionen der frühkindlichen Reflexe und die Motorische
        Entwicklung, im Kontext zu traumatischen Erfahrungen (eigene/generationsübergreifend) zu
        sehen und diese „primär“ in die „Balancearbeit“ miteinzubeziehen.</p>

    <h2>4. Integration negativer Erlebnisse während der Schwangerschaft, bei der Geburt
        und in der frühkindlichen Entwicklung</h2>
    <p>(Begleitung, Beratung, Coaching und Training im Beziehungs- und Erziehungsalltag)</p>

    <p>
        Aus eigener und aus der langjährigen Erfahrung meiner Praxisarbeit, habe ich die
        Erkenntnis gewonnen, das Schwangerschaft, Geburt und die ersten Lebensjahre, einen
        Menschen ein Leben lang, nachhaltig auf allen Ebenen, beeinflussen können.
        Zum einen weil die negativen Erlebnisse während der Schwangerschaft, bei der Geburt und
        in der frühkindlichen Entwicklung zu den anhaltenden/restaktiven Frühkindlichen Reflexen
        führen können, aber auch, weil die Geburt meiner Meinung nach, die „erste aktive
        Lernerfahrung“ darstellt und tief im Menschen verankert ist.
    </p>

    <p>
        Aus einer Atmosphäre des natürlichen Wachsens tritt das Kind, durch das Geboren werden
        „die Reise auf die Erde“ an.
        Durch den Geburtsprozess findet die „Vorbereitung“ auf das sich „Anpassen“ an die „neuen
        Lebensbedingen“ statt.
    </p>

    <p>
        Hier wird meiner Erfahrung nach, neben dem was ein Mensch in seinen Anlagen mitbringt
        und dem, was seine Erziehung prägt, ein Grundstein dafür gelegt, wie ein Mensch mit
        zukünftigen An- und Herausforderungen umgehen wird.
    </p>

    <p>
        Ich habe so etwas wie einen „roten Faden“ entdeckt, an dem ich ableiten kann, zu welchen
        Reaktionen, Handlungsmustern und Lösungsstrategien ein Mensch tendiert, wenn die
        Schwangerschaft und/oder Geburt, diese oder jene Voraussetzung, Herausforderung oder
        Belastung beinhaltet hat.
        Diese alle zu benennen würde zu umfangreich werden und würde vor allem der Individualität, eines von Eltern, mir
        geschilderten Geburtsablaufes, nicht erfassen.
    </p>

    <p>
        Allgemein kann ich sagen, dass ich festgestellt habe, dass Kinder, deren Geburt sehr lange
        gedauert hat und die auf Grund von Komplikationen z.B. mit einer Zange, Sauglocke,
        afrikanischer Wickeltechnik, herausdrücken oder durch einen Kaiserschnitt beendet werden
        musste, dazu tendieren, z.B. bei „Anstrengungsherausforderungen“ schnell aufzugeben.
    </p>

    <p>
        Das geht meistens mit dem „subjektiven Empfinden“, „nicht weiter zu kommen“, „es hat
        keinen Sinn sich anzustrengen“, „ich schaffe das sowieso nicht“, einher.
    </p>

    <p>
        Es kann auch sein, dass jemand Anforderungen (Lernen/Alltag) oder „Grenzsetzungen“
        ausweicht oder als enormen „Druck“ empfindet und darauf emotional (aggressiv/wütend
        oder weinig mit Selbstvertrauen/ Rückzug) überreagiert. Hier wirken sich ggf. die restaktiven
        frühkindlichen Überlebensreflexe (Kampf-Flucht-Reflex, Furchtlähmungsreflex/Erstarren)
        aus.
    </p>

    <p>
        Wurden Mutter und Kind nach der Geburt getrennt, können sich hier unsichere Verhaltenstendenzen entwickeln, die sich
        z.B. später in Trennungsängsten zeigen können.
    </p>

    <p>
        Bereits 1981 hat Dr. med. Thomas Verny, Psychiater und Geburtshelfer in dem Buch: „Das
        Seelenleben des Ungeborenen“ wissenschaftliche Untersuchungen zusammengefasst.
        Eindrucksvoll schildert er, dass das Ungeborene ein aufmerksames, lernendes Wesen ist
        und ein aktives Seelenleben hat. Er macht auf verschiedene Aspekte aufmerksam, die dazu
        beitragen können wie es in seinem tiefsten Urvertrauen erschüttert werden kann.
    </p>

    <p>
        In den neunziger Jahren begann man in England und Irland mit einer Langzeitstudie,
        (Epicure-Studie), die sich u.a. damit befasste, wie sich „frühgeborene“ Kinder im Vergleich
        zu „normal“ geborenen Kindern entwickeln.
        Hier wurden Erkenntnisse darüber gewonnen, dass Frühchen und häufig auch die Eltern
        traumatisiert sind und dass sich das besonders auf die Bindungs- und Beziehungsebene
        auswirkt.
        Zudem zeigte sich auch, dass die Kinder häufiger Verhaltensstörungen entwickeln und auch
        in ihrer motorischen und kognitiven Entwicklung größere Defizite haben. Es wird angeführt,
        dass frühgeborene Kinder häufiger von Autismus betroffenen sind.
    </p>

    <p>
        Auch Julia Jäkel, Entwicklungspsychologin von der Ruhruniversität Bochum befasste sich
        damit, welche Auswirkungen die gestiegene Anzahl von Frühgeborenen hat und schreibt
        dazu in einem Fachartikel: „Eine Herausforderung für das Schulsystem“.
    </p>

    <p>
        In meiner Praxisarbeit habe ich festgestellt, dass lebensbedrohliche, aber auch erschwerte
        und von der „Norm abweichende Geburtsverläufe“ nicht nur zu den beschrieben
        restaktiven/anhaltenden Reflexen beitragen können, sondern dass den Eltern, die Geburt
        „als schreckliches Erlebnis“ in Erinnerung bleibt. In der Fachwelt wird hier von einer
        „Geburtstraumatisierung“ für Mutter und Kind gesprochen, von der auch, der bei der Geburt
        anwesende Vater betroffen sein kann.
    </p>

    <p>
        Für mich ist es kein „Zufall“, dass Eltern, die ihre Kinder mit „gravierenden“ Bewegungs-und
        Wahrnehmungsdefiziten - u./o. mit Lern- und Konzentrationsproblemen - u./o. mit emotionalen und sozialen
        „Verhaltensauffälligkeiten“ in meiner Praxis vorgestellt haben - alle -
        ohne Ausnahme, von einer durch Stressfaktoren begleiteten Schwangerschaft und/oder
        Geburt, sowie einer oftmals schwierigen und anstrengenden Säuglingszeit berichteten.
    </p>

    <p>
        Diese Erkenntnisse haben mich bewogen, als Pädagogin, Begleitende Kinesiologin und
        Systemische Familien/Therapeutin - Müttern, Vätern, Eltern und Kindern, mit dem
        Reeducating I.D.© Hilfestellungen an zu bieten - eine durch negative Schwangerschaftsund/oder Geburtserlebnisse
        „gestörte Bindung“ (neu) anzubahnen und damit zu
        ermöglichen, dass Beziehung und auch Erziehung sich (neu) gestalten kann.
    </p>

    <p>
        Der Neurobiologe und Gehirnforscher Prof. Dr. Gerald Hüther schreibt in seinem Buch:
        „Neues vom Zappelphilipp“:
    </p>

    <p><i>„Neuere Forschungsergebnisse zeigen, dass auch der Verlauf eines Geburtsvorganges
            eine entscheidende Weichenstellung für die weitere Entwicklung der Beziehung zwischen
            Mutter und Kind darstellt.
            Ebenso wie eine Schwangerschaft mehr oder weniger „gelingen“ mag, kann auch eine
            Geburt selbst zu einer Stärkung, bisweilen aber auch zu einer Störung der frühen
            Beziehung zwischen Mutter und ihrem neugeborenen Kind führen.“
        </i></p>

    <p>Das entspricht meinen Erfahrungen. Ich konnte beobachten, das ein „negativer
        Schwangerschafts- und Geburtsverlauf, eine Beziehung zwischen Mutter/Eltern und Kind
        entstehen lassen kann, die von Sorgen, Ängsten und Kontrolle geprägt ist, was sich z. B.
        bei dem Kind und bei den Eltern, in der „Angst loszulassen“ zeigen kann. Oftmals entsteht
        hier zwischen Mutter/Eltern und Kind ein Kreislauf aus Hilflosigkeit, Verzweiflung, Ohnmacht
        und auch Wut.
    </p>

    <p>Ein „Sorgenkind“ kann in den Mittelpunkt rücken, was gegebenenfalls dazu führt, dass
        Geschwisterkinder „zurückstehen“ müssen. Das kann sich wiederum nachteilig auf diese
        „Eltern/Mutter-Kind-Beziehung und auf die Geschwisterbeziehung auswirken.
    </p>

    <p>
        Des Weiteren kann eine „nicht gelungene Schwangerschaft und/oder Geburt“, Fehl- und
        Totgeburten - zu Stress und Ängsten in nachfolgenden Schwangerschaften führen, was
        sich dann möglicherweise wiederrum negativ auf das ungeborene Kind auswirkt.
    </p>

    <p>
        Als systemische Familientherapeutin habe ich festgestellt, dass sich „negative
        „Schwangerschafts- und Geburtsthemen“ sowie nicht verarbeitete Verluste (Tod, Adoption,
        Pflege) von Kindern, sich „auffallend“ oft - wie ein roter Faden - durch „Generationen“
        hindurchzieht.
        Meiner Meinung und Erfahrung nach macht es Sinn, eine „belastete“ Schwangerschaft
        und/oder Geburt so schnell wie möglich für Mutter, (Vater) und den Säugling
        „präventiv/vorsorgend“ zu bearbeiten/balancieren und zu integrieren, damit sich die
        Stressoren weder negativ auf die Beziehungsebene zwischen Mutter, Vater, Eltern und
        Kind, noch als blockierende Langzeitfolgen bei dem Kind auswirken können.
        Auch in einer Schwangerschaft in der es körperliche oder emotionale Herausforderungen
        gibt, kann um den „bestehenden Stress“ abzubauen, kinesiologisch balanciert/gearbeitet
        werden.
    </p>

    <p>
        In der kiensiologischen Praxis gibt es die Erfahrung, dass schwangere Frauen besonders
        präventive „Balancen“ zur Vorbereitung auf eine „gelingende Geburt“, als hilfreich und
        unterstützend empfinden.
        Hier wird eine Möglichkeit dafür geschaffen, dass der Start - in das „neue Leben“ - für alle
        Beteiligten - positiv gelingen kann.
    </p>

    <h2>5. Methodisches Arbeiten: Die Inward Attention, („die nach innen gerichtete Aufmerk-
        samkeit“) das „Herzstück“ im Reeducating I.D.©</h2>
    <p>
        Die „nach innen gerichtete Aufmerksamkeit“ bietet die Möglichkeit, auf das formulierte „Ziel
        ausgerichtet“, im Inneren, sonst eher, „unbewusste Abläufe und Reaktionen“
        wahrzunehmen.
        Bei diesem Vorgehen, können Erwachsene/Eltern und ihre Kinder, durch von mir speziell
        entwickelte Übungen und Rollenspiele, über die subjektive Eigenwahrnehmung, Intuition
        und Imagination, „eine (gemeinsame) Reise zurück“, durch die Schwangerschaft, die
        Geburt und durch Abläufe verschiedener Entwicklungsphasen machen.
        Ich begleite den Klienten/die Systeme und mache gegebenenfalls methodische Angebote,
        bei körperlichen, emotionalen und energetisch-feinstofflichen „Sensationen“, damit diese
        bestmöglich verändert und „ganz-heitlich integriert“ werden können.
    </p>
    <p>
        Beim „Zurückgehen“ kann - „Altes“ - „Blockierendes“ - erfahren, gespürt, erlebt und
        losgelassen werden.
        Gleichzeitig wird dadurch Platz für „Neues“ geschaffen und eine “neue Erziehung“ - auf
        allen Ebenen - kann beginnen.
    </p>
    <p>
        Das gelingt meiner Erfahrung nach dann am besten - wenn an das - was im Inneren - „ganz
        und heil“ geblieben ist - angeknüpft werden kann. Dabei bilden die eigenen Ressourcen und
        Potentiale die Basis, diese sind die „Impulsgeber“, welche meiner Meinung nach, die
        „Fähigkeit zur „Selbstregulation des ganzheitlichen Zusammenspiels“ im Menschen
        anregen.
    </p>
    <p>
        Das aus dem „Gleichgewicht geratene“, das „schief Gewordene“, kann mit Hilfe der „innen
        wohnenden Kräfte“ (wieder) in die Balance gelangen.
        So kann eine „Basis“ zum „Verwurzeln“ geschaffen werden - was die Voraussetzung dafür
        ist - dass „Flügel“ wachsen können.
    </p>
</template>